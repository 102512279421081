import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/login`, { email, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('email', email);
      navigate('/tenho-duvida');
    } catch (error) {
      setError('Credenciais inválidas');
      console.error('Erro ao fazer login', error);
    }
  };

  return (
    <div style={styles.container}>
      <img src="/logotipo.png" alt="Logotipo" style={styles.logo} />
      <h2 style={styles.title}>Entre em sua Conta</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            placeholder="Digite seu E-mail"
            required
          />
        </div>
        <div style={styles.formGroup}>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
            placeholder="Digite sua senha!"
            required
          />
        </div>
        <button type="submit" style={styles.button}>Login</button>
        {error && <p style={styles.error}>{error}</p>}
      </form>
      <p style={styles.signupLink}>
        Não tem uma conta? <a href="/signup" style={styles.link}>Cadastre-se</a>
      </p>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '40px',
    maxWidth: '400px',
    margin: '100px auto',
    textAlign: 'center',
  },
  logo: {
    width: '100px',
    marginBottom: '30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '20px',
  },
  input: {
    padding: '15px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    fontSize: '16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'border-color 0.3s ease',
  },
  inputFocus: {
    borderColor: '#e83e8c',
  },
  button: {
    padding: '12px',
    backgroundColor: '#e83e8c',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '18px',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#c03970',
  },
  error: {
    color: '#e74c3c',
    marginTop: '15px',
  },
  signupLink: {
    marginTop: '15px',
    fontSize: '14px',
    color: '#555',
  },
  link: {
    color: '#e83e8c',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

export default Login;