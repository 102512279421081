import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './TenhoDuvida.css';

const TenhoDuvida = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const chatWindowRef = useRef(null); // Referência para a janela de chat

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const email = localStorage.getItem('email');
        const token = localStorage.getItem('token');

        if (email && token) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-questions`, {
            params: { email },
            headers: { Authorization: `Bearer ${token}` }
          });
          setHistory(response.data.questions);
        }
      } catch (error) {
        console.error('Erro ao buscar histórico', error);
      }
    };

    fetchHistory();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (question.trim() === '') {
      alert('A pergunta não pode estar vazia.');
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ask`, { question }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAnswer(response.data.answer);

      const email = localStorage.getItem('email');
      if (email && token) {
        const historyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/get-questions`, {
          params: { email },
          headers: { Authorization: `Bearer ${token}` }
        });
        setHistory(historyResponse.data.questions);

        // Rolagem automática para a última pergunta
        if (chatWindowRef.current) {
          chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
      }
    } catch (error) {
      console.error('Erro ao enviar a pergunta', error);
      alert('Erro ao enviar a pergunta. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    navigate('/login');
  };

  return (
    <div className="container">
      <div className="main-content">
        <h1>Faça sua Pergunta:</h1>
        <div className="chat-window" ref={chatWindowRef}>
          {history.length === 0 ? (
            <p>Sem perguntas anteriores.</p>
          ) : (
            history.map((item, index) => (
              <div key={index} className="chat-bubble">
                <p><strong>Pergunta:</strong> {item.question}</p>
                <p><strong>Resposta:</strong> {item.answer}</p>
              </div>
            ))
          )}
        </div>
        <form onSubmit={handleSubmit} className="chat-form">
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Digite sua pergunta..."
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
      </div>
      <div className="bottom-menu">
        <button onClick={() => navigate('/tenho-duvida')}>
          <i className="fas fa-question"></i>
        </button>
        <button onClick={() => navigate('/historinhas')}>
          <i className="fas fa-book"></i>
        </button>
        <button onClick={() => navigate('/faca-um-desenho')}>
          <i className="fas fa-pen"></i>
        </button>
        <button onClick={() => navigate('/cantinho-da-crianca')}>
          <i className="fas fa-child"></i>
        </button>
        <button onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
    </div>
  );
};

export default TenhoDuvida;
